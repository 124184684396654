exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jokes-tsx": () => import("./../../../src/pages/jokes.tsx" /* webpackChunkName: "component---src-pages-jokes-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-speed-typing-tsx": () => import("./../../../src/pages/speed-typing.tsx" /* webpackChunkName: "component---src-pages-speed-typing-tsx" */),
  "component---src-pages-weather-tsx": () => import("./../../../src/pages/weather.tsx" /* webpackChunkName: "component---src-pages-weather-tsx" */)
}

